import { createRouter, createWebHistory } from 'vue-router'

// Design System Routes
const designSystemChildRoutes = (prefix) => [
  // {
  //   path: '',
  //   name: prefix + '.main',
  //   meta: { auth: true, name: 'Design System' },
  //   component: () => import('@/views/design-system/IndexPage.vue')
  // }
  {
    path: '',
    name: prefix + '.login',
    meta: { auth: false, name: 'Design System' },
    component: () => import('@/views/auth/default/SignIn.vue')
  }
]
// Auth Default Routes
const authChildRoutes = (prefix) => [
  {
    path: 'login',
    name: prefix + '.login',
    meta: { auth: true, name: 'Login' },
    component: () => import('@/views/auth/default/SignIn.vue')
  },
  {
    path: 'register',
    name: prefix + '.register',
    meta: { auth: true, name: 'Register' },
    component: () => import('@/views/auth/default/SignUp.vue')
  },
  {
    path: 'send-password',
    name: prefix + '.send-password',
    meta: { auth: false, name: 'Send Password' },
    component: () => import('@/views/auth/default/SendPassword.vue')
  },
  {
    path: 'reset-password/:token',
    name: prefix + '.reset-password',
    meta: { auth: false, name: 'Reset Password' },
    component: () => import('@/views/auth/default/ResetPassword.vue')
  },
  {
    path: 'varify-email',
    name: prefix + '.varify-email',
    meta: { auth: false, name: 'Varify Email' },
    component: () => import('@/views/auth/default/VarifyEmail.vue')
  },
  {
    path: 'lock-screen',
    name: prefix + '.lock-screen',
    meta: { auth: false, name: 'Lock Screen' },
    component: () => import('@/views/auth/default/LockScreen.vue')
  }
]

// Dashboard routes
const dashboardRoutes = (prefix) => [
  {
    path: '/user',
    name: prefix + '.dashboard',
    meta: { auth: true, name: 'Home', isBanner: true },
    component: () => import('@/views/dashboards/IndexPage.vue')
  },
  {
    path: '/vehiculosuser',
    name: prefix + '.vehiculos',
    meta: { auth: true, name: 'Vehiculos', isBanner: false },
    component: () => import('@/views/vehiculos/VehiculosMain.vue')
  },
  {
    path: '/vehiculo/datos',
    name: prefix + '.vehiculodatos',
    meta: { auth: true, name: 'vehiculodatos', isBanner: false },
    component: () => import('@/views/vehiculos/VehiculoData.vue')
  }
]
// Default routes
const defaultChildRoutes = (prefix) => [
  {
    path: '',
    name: prefix + '.dashboard',
    meta: { auth: true, name: 'Home', isBanner: true },
    component: () => import('@/views/auth/default/SignIn.vue')
  },
  {
    path: '/mapa',
    name: prefix + '.mapa',
    meta: { auth: true, name: 'Mapa', isBanner: false },
    // component: () => import('@/views/maps/MapRutasVehiculos.vue')
    component: () => import('@/views/maps/MapUbicacionesView.vue')
  },
  {
    path: '/empresas',
    name: prefix + '.empresas',
    meta: { auth: true, name: 'Empresas', isBanner: false },
    component: () => import('@/views/empresas/EmpresasMain.vue')
  },
  {
    path: '/empresa',
    name: prefix + '.empresa',
    meta: { auth: true, name: 'Empresa', isBanner: false },
    component: () => import('@/views/empresas/EmpresaView.vue')
  },
  {
    path: '/flota',
    props: true,
    name: prefix + '.flota',
    meta: { auth: true, name: 'Flota', isBanner: false },
    component: () => import('@/views/flotas/FlotaView.vue')
  },
  {
    path: '/flotas',
    name: prefix + '.flotas',
    meta: { auth: true, name: 'Flotas', isBanner: false },
    component: () => import('@/views/flotas/FlotasMain.vue')
  },
  {
    path: '/almacenes',
    name: prefix + '.almacenes',
    meta: { auth: true, name: 'Almacenes', isBanner: false },
    component: () => import('@/views/almacenes/AlmacenesMain.vue')
  },
  {
    path: '/almacen',
    name: prefix + '.almacen',
    meta: { auth: true, name: 'Almacen', isBanner: false },
    component: () => import('@/views/almacenes/AlmacenView.vue')
  },
  {
    path: '/neumaticos',
    name: prefix + '.neumaticos',
    meta: { auth: true, name: 'Neumaticos', isBanner: false },
    component: () => import('@/views/almacenes/NeumaticosAlmacen.vue')
  },
  {
    path: '/historico',
    name: prefix + '.historico',
    meta: { auth: true, name: 'Historico', isBanner: false },
    component: () => import('@/views/historico/HistoricoMain.vue')
  },
  {
    path: '/vehiculos',
    name: prefix + '.vehiculos',
    meta: { auth: true, name: 'Vehiculos', isBanner: false },
    component: () => import('@/views/vehiculos/VehiculosMain.vue')
  },
  {
    path: '/vehiculo',
    name: prefix + '.vehiculo',
    meta: { auth: true, name: 'Vehiculo', isBanner: false },
    component: () => import('@/views/vehiculos/VehiculoView.vue')
  },
  {
    path: '/vehiculo/data',
    name: prefix + '.vehiculoconf',
    meta: { auth: true, name: 'VehiculoData', isBanner: true },
    component: () => import('@/views/vehiculos/VehiculoData.vue')
  },
  {
    path: '/usuarios',
    name: prefix + '.usuarios',
    meta: { auth: true, name: 'Usuarios', isBanner: false },
    component: () => import('@/views/usuarios/UsuariosMain.vue')
  },
  {
    path: '/usuario',
    name: prefix + '.usuario',
    meta: { auth: true, name: 'Usuario', isBanner: false },
    component: () => import('@/views/usuarios/UsuarioView.vue')
  },
  {
    path: '/alarmas',
    name: prefix + '.alarmas',
    meta: { auth: true, name: 'Alarmas', isBanner: false },
    component: () => import('@/views/alarmas/alarmasMain.vue')
  },
  {
    path: '/estadisticas',
    name: prefix + '.estadisticas',
    meta: { auth: true, name: 'Estadisticas', isBanner: false },
    component: () => import('@/views/estadisticas/estadisticasMain.vue')
  },
  {
    path: '/plantilla',
    name: prefix + '.estadisticasplantilla',
    meta: { auth: true, name: 'Estadisticas', isBanner: false },
    component: () => import('@/views/estadisticas/estadisticasPlantilla.vue')
  },
  // Spacial Pages
  {
    path: '/billing',
    name: prefix + '.billing',
    meta: { auth: true, name: 'Billing', isBanner: false },
    component: () => import('@/views/spacial-pages/BillingPage.vue')
  },
  {
    path: '/calender',
    name: prefix + '.calender',
    meta: { auth: true, name: 'Calender', isBanner: false },
    component: () => import('@/views/spacial-pages/CalenderPage.vue')
  },
  {
    path: '/kanban',
    name: prefix + '.kanban',
    meta: { auth: true, name: 'Kanban', isBanner: false },
    component: () => import('@/views/spacial-pages/KanbanPage.vue')
  },
  {
    path: '/pricing',
    name: prefix + '.pricing',
    meta: { auth: true, name: 'Pricing', isBanner: false },
    component: () => import('@/views/spacial-pages/PricingPage.vue')
  },
  {
    path: '/timeline',
    name: prefix + '.timeline',
    meta: { auth: true, name: 'Timeline', isBanner: false },
    component: () => import('@/views/spacial-pages/TimelinePage.vue')
  },
  {
    path: '/rtl-support',
    name: prefix + '.rtlsupport',
    meta: { auth: true, name: 'RTL-Support', isBanner: false },
    component: () => import('@/views/spacial-pages/RtlSupport.vue')
  },
  // Users Pages
  {
    path: '/user-list',
    name: prefix + '.user-list',
    meta: { auth: true, name: 'User List', isBanner: false },
    component: () => import('@/views/user/ListPage.vue')
  },
  {
    path: '/user-add',
    name: prefix + '.user-add',
    meta: { auth: true, name: 'User Add', isBanner: false },
    component: () => import('@/views/user/AddPage.vue')
  },
  {
    path: '/anyadir-usuario',
    name: prefix + '.usuario-add',
    meta: { auth: true, name: 'AnyadirUsuario', isBanner: false },
    component: () => import('@/views/user/AnyadirUsuarioPage.vue')
  },
  {
    path: '/anyadir-municipio',
    name: prefix + '.municipio-add',
    meta: { auth: true, name: 'AnyadirMunicipio', isBanner: false },
    component: () => import('@/views/user/AnyadirMunicipioPage.vue')
  },
  {
    path: '/user-profile',
    name: prefix + '.user-profile',
    meta: { auth: true, name: 'User Add', isBanner: false },
    component: () => import('@/views/user/ProfilePage.vue')
  },
  {
    path: '/privacy-setting',
    name: prefix + '.user-privacy-setting',
    meta: { auth: true, name: 'Privacy Setting', isBanner: false },
    component: () => import('@/views/user/PrivacySetting.vue')
  },
  // Widgets Pages
  {
    path: '/widget-basic',
    name: prefix + '.widget-basic',
    meta: { auth: true, name: 'Widget Basic', isBanner: false },
    component: () => import('@/views/widgets/WidgetBasic.vue')
  },
  {
    path: '/widget-chart',
    name: prefix + '.widget-chart',
    meta: { auth: true, name: 'Widget Chart', isBanner: true },
    component: () => import('@/views/widgets/WidgetChart.vue')
  },
  {
    path: '/widget-card',
    name: prefix + '.widget-card',
    meta: { auth: true, name: 'Widget Card', isBanner: false },
    component: () => import('@/views/widgets/WidgetCard.vue')
  },
  // Map Pages
  {
    path: '/map-google',
    name: prefix + '.map-google',
    meta: { auth: true, name: 'Google Map', isBanner: false },
    component: () => import('@/views/maps/GooglePage.vue')
  },
  {
    path: '/map-vector',
    name: prefix + '.map-vector',
    meta: { auth: true, name: 'Vector Map', isBanner: false },
    component: () => import('@/views/maps/VectorPage.vue')
  },
  // Form Pages
  {
    path: '/elements',
    name: prefix + '.elements',
    meta: { auth: true, name: 'Elements', isBanner: false },
    component: () => import('@/views/forms/ElementsPage.vue')
  },
  {
    path: '/validation',
    name: prefix + '.validation',
    meta: { auth: true, name: 'Validation', isBanner: false },
    component: () => import('@/views/forms/ValidationPage.vue')
  },
  {
    path: '/wizard',
    name: prefix + '.wizard',
    meta: { auth: true, name: 'Wizard', isBanner: false },
    component: () => import('@/views/forms/WizardPage.vue')
  },
  // Table Pages
  {
    path: '/bootstrap-table',
    name: prefix + '.bootstrap-table',
    meta: { auth: true, name: 'Botstrap Table', isBanner: false },
    component: () => import('@/views/tables/BootstrapTable.vue')
  },
  {
    path: '/datatable',
    name: prefix + '.data-table',
    meta: { auth: true, name: 'Data Table', isBanner: false },
    component: () => import('@/views/tables/DataTable.vue')
  },
  // Icons Pages
  {
    path: '/icons/solid',
    name: prefix + '.icons.solid',
    meta: { auth: true, name: 'Solid Icon', isBanner: false },
    component: () => import('@/views/icons/SolidIcon.vue')
  },
  {
    path: '/icons/outlined',
    name: prefix + '.icons.outlined',
    meta: { auth: true, name: 'Outlined Icon', isBanner: false },
    component: () => import('@/views/icons/OutlinedIcon.vue')
  },
  {
    path: '/icons/dual-tone',
    name: prefix + '.icons.dual-tone',
    meta: { auth: true, name: 'Dual Tone Icon', isBanner: false },
    component: () => import('@/views/icons/DualToneIcon.vue')
  },
  // Extra Pages
  {
    path: '/privacy-policy',
    name: prefix + '.privacy-policy',
    meta: { auth: true, name: 'Privacy Policy', isBanner: false },
    component: () => import('@/views/extra/PrivacyPolicy.vue')
  },
  {
    path: '/terms-and-conditions',
    name: prefix + '.terms-and-conditions',
    meta: { auth: true, name: 'Terms and Conditions', isBanner: false },
    component: () => import('@/views/extra/TermsAndConditions.vue')
  },
  {
    path: '/admin-permissions',
    name: prefix + '.admin-permissions',
    meta: { auth: true, name: 'Admin Permissions', isBanner: false },
    component: () => import('@/views/admin/AdminPage.vue')
  }
]

const errorRoutes = (prefix) => [
  // Error Pages
  {
    path: '404',
    name: prefix + '.404',
    meta: { auth: true, name: 'Error 404', isBanner: false },
    component: () => import('@/views/errors/Error404Page.vue')
  },
  {
    path: '500',
    name: prefix + '.500',
    meta: { auth: true, name: 'Error 500', isBanner: false },
    component: () => import('@/views/errors/Error500Page.vue')
  },
  {
    path: 'maintenance',
    name: prefix + '.maintenance',
    meta: { auth: true, name: 'Maintenance', isBanner: false },
    component: () => import('@/views/errors/MaintenancePage.vue')
  }
]

const routes = [
  {
    path: '/vehiculosuser',
    name: 'user',
    component: () => import('../layouts/PruebaLayout.vue'),
    children: dashboardRoutes('nuevo')
  },
  {
    path: '/',
    name: 'design-system',
    component: () => import('../layouts/guest/BlankLayout.vue'),
    children: designSystemChildRoutes('design-system')
  },
  // Default Pages
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../layouts/DefaultLayout.vue'),
    children: defaultChildRoutes('default')
  },
  // Menu Styles
  {
    path: '/horizontal',
    name: 'horizontal',
    component: () => import('../layouts/menu-styles/HorizontalLayout.vue'),
    children: dashboardRoutes('horizontal')
  },
  {
    path: '/dual-horizontal',
    name: 'dual-horizontal',
    component: () => import('../layouts/menu-styles/DualHorizontalLayout.vue'),
    children: dashboardRoutes('dual-horizontal')
  },
  {
    path: '/dual-compact',
    name: 'dual-compact',
    component: () => import('../layouts/menu-styles/DualCompactLayout.vue'),
    children: dashboardRoutes('dual-compact')
  },
  {
    path: '/boxed',
    name: 'boxed',
    component: () => import('../layouts/menu-styles/BoxedLayout.vue'),
    children: dashboardRoutes('boxed')
  },
  {
    path: '/boxed-fancy',
    name: 'boxed-fancy',
    component: () => import('../layouts/menu-styles/BoxedFancyLayout.vue'),
    children: dashboardRoutes('boxed-fancy')
  },

  // Auth Skins
  {
    path: '/auth',
    name: 'auth',
    component: () => import('../layouts/guest/BlankLayout.vue'),
    children: authChildRoutes('auth')
  },
  // Errors Pages
  {
    path: '/errors',
    name: 'errors',
    component: () => import('../layouts/guest/BlankLayout.vue'),
    children: errorRoutes('errors')
  }
]

const router = createRouter({
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes
})

/* router.beforeEach((to, from, next) => {
  const userRole = localStorage.getItem('role')
  const currentPath = window.location.pathname
  console.log('router.beforeEach_from', from)
  console.log('router.beforeEach_to', to)
  console.log('userRole', userRole)
  console.log('currentPath', currentPath)
  if (userRole === 'ADMIN') {
    next('/dashboard')
  } else if (userRole === 'USER') {
    next('/user')
  } else {
    next()
  }
}) */

export default router
